<div class="skeleton-container">
    @for(item of skeletonItems; track $index) {
    <div class="row mt-2 d-flex justify-content-end mt-3">
        <div class="col-xxl-8 col-xl-9 col-lg-12 col-md-12">
            <div class="form-check mb-2">
                <ngx-skeleton-loader count="1" appearance="circle"
                    [theme]="{ 'width': '20px', 'height': '20px' }"></ngx-skeleton-loader>
                <div class="form-check-label" for="flexCheckDefault">
                    <ngx-skeleton-loader count="1"
                        [theme]="{ 'width': '150px', 'height': '20px', 'margin-left': '30px' }"></ngx-skeleton-loader>
                </div>
            </div>
        </div>
    </div>

    }
</div>