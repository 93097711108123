<!-- <nav class="navbar navbar-expand-lg navbar-light bg-white border">
    <div class="container">
        <a class="" href="#">
            <img [src]="Hamburgermenu" alt="">
        </a>
        <a class="navbar-brand" href="#">
            <img [src]="themeLogo" alt="Logo">
        </a>

        <div class="p-2 d-flex  align-content-center location-search-input align-items-center">
            <img src="assets/images/tm/icons/location-search-icn.svg" class="mr-2" alt="pin location">
            <input type="text" placeholder="Search for area, street name, landmark…" class="w-100 f-16 f-m-14 pe-1 ps-3"
                autocomplete="off">


        </div>
        <div class="p-2 d-flex align-content-center location-search-input align-items-center">
            <img src="assets/images/tm/icons/location-search-icn.svg" class="mr-2" alt="pin location">
            <input type="text" placeholder="Search for area, street name, landmark…" class="w-100 f-16 f-m-14 pe-1 ps-3" value="{{location.primaryText}}"
                autocomplete="off" [readOnly]="true">

                <img src="assets/images/tm/icons/locaction-marker-icn.svg" class="clickable" alt="clear selected location" >
        </div>
        <div class="collapse navbar-collapse d-none align-content-center d-lg-flex" id="navbarDeliveryPickup">
            <div class="btn-group custom-btn-group" role="group" aria-label="Delivery and Pickup">
                <button type="button" class="btn btn-delivery ">Delivery</button>
                <button type="button" class="btn btn-pickup active">Pickup</button>
            </div>
        </div>

   
    </div>
</nav> -->

<nav
  class="navbar navbar-expand-lg navbar-light bg-white border font-size-md-12"
>
  <div class="container">

    <div class="col-1">
      <!-- Left Section: Hamburger Menu and Logo -->
      <div class="d-flex align-items-center d">
        <!-- <a class="me-3 hamburgerIcon" href="#">
        <img [src]="Hamburgermenu" alt="Hamburger Menu">
    </a> -->
        <a class="navbar-brand" href="#">
          <img [src]="themeLogo" alt="Logo" />
        </a>
      </div>
    </div>

    <div class="col-10 font-size-sm-8 d-md-none">
      <div
        class="p-2 d-flex align-content-center location-search-input loc-search-mobile align-items-center ms-2 clickable"
        (click)="openLocationModal('open')"
      >
        <img
          src="assets/images/tm/icons/location-search-icn.svg"
          class="me-2"
          alt="pin location"
        />
        <input
          type="text"
          placeholder=""
          id="common-loc-search"
          class="w-100 clickable font-size-sm-8 f-8"
          [(ngModel)]="searchtext"
          autocomplete="off"
          [readOnly]="true"
        />
        <img
          src="assets/images/tm/icons/input-arrow-down.png"
          class="ms-2 clickable"
          alt="clear selected location"
        />
      </div>
    </div>

    <div class="col-8 col-md-9 d-none d-md-block">
      <!-- Middle Section: Search Bars with Increased Width -->
      <div class="d-flex flex-grow-1 justify-content-center align-items-center" (click)="openLocationModal('open')">
        <div
          class="p-2 d-flex align-content-center location-search-input align-items-center me-lg-4 me-md-2 flex-grow-1"
          
        >
          <img
            src="assets/images/tm/icons/Ic_home_search.png"
            class="me-2"
            alt="pin location"
          />
          <input
            type="text"
            placeholder="Search restaurants, stores, items, Etc."
            id="common-search"
            class="w-100"
            autocomplete="off"
            (input)="setSearchTextChanged($event)"
          />
        </div>
        <div
          class="p-2 d-flex align-content-center location-search-input align-items-center ms-2 clickable"
          
        >
          <img
            src="assets/images/tm/icons/location-search-icn.svg"
            class="me-2"
            alt="pin location"
          />
          <input
            type="text"
            placeholder=""
            id="common-location-search"
            class="w-100 clickable"
            [(ngModel)]="searchtext"
            autocomplete="off"
            [readOnly]="true"
          />
          <img
            src="assets/images/tm/icons/input-arrow-down.png"
            class="ms-2 clickable"
            alt="clear selected location"
          />
        </div>
      </div>
    </div>

    <!-- Right Section: Delivery/Pickup Buttons and Cart -->

    <div class="col-3 col-md-2 d-none d-md-block">
      <div class="d-flex justify-content-end align-items-center p-2">
        <div
          class="btn-group custom-btn-group me-3 font-size-md-12 ms-2"
          role="group"
          aria-label="Delivery and Pickup"
        >
          <button
            type="button"
            class="btn btn-delivery"
            [class]="orderType == DELIVERY_TYPE ? 'active' : ''"
            (click)="orderTypeClick(DELIVERY_TYPE)"
          >
            <span class="font-size-md-12"> Delivery </span>
          </button>
          <button
            type="button"
            class="btn btn-pickup"
            [class]="orderType == PICKUP_TYPE ? 'active' : ''"
            (click)="orderTypeClick(PICKUP_TYPE)"
          >
            <span class="font-size-md-12"> Pickup </span>
          </button>
        </div>
      </div>
    </div>
    
  </div>
</nav>

<div class="container-fluid d-md-none">
  <div class="row mt-2 mobile-search">
    <div class="col-10 pr-0">
      <div
        class="p-2 d-flex align-content-center location-search-input align-items-center me-2 flex-grow-1"
      >
        <img
          src="assets/images/tm/icons/Ic_home_search.png"
          class="me-2"
          alt="pin location"
        />
        <input
          type="text"
          placeholder="Search"
          id="common-text-search"
          class="w-100 font-size-sm-8 f-8"
          autocomplete="off"
          (input)="setSearchTextChanged($event)"
        />
      </div>
    </div>

    <div class="col-2 p-0">
      <button type="button" class="btn btn-filter" (click)="onToggleDrawer()">
        <i class="fa fa-filter"></i>
      </button>
    </div>
    <div class="col-12"></div>
  </div>
  <div class="row mt-2">
    <div class="col-12">
      <div class="d-flex align-items-center p-1">
        <div
          class="btn-group custom-btn-group font-size-12 w-100"
          role="group"
          aria-label="Delivery and Pickup"
        >
          <button
            type="button"
            class="btn btn-delivery p-0"
            [class]="orderType == DELIVERY_TYPE ? 'active' : ''"
            (click)="orderTypeClick(DELIVERY_TYPE)"
          >
            <span class="font-size-12"> Delivery </span>
          </button>
          <button
            type="button"
            class="btn btn-pickup p-0"
            [class]="orderType == PICKUP_TYPE ? 'active' : ''"
            (click)="orderTypeClick(PICKUP_TYPE)"
          >
            <span class="font-size-12"> Pickup </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
