<!--footer section -->
<!-- <div class="mt-5"></div> -->

<footer [ngClass]="class">
    <div class="w-1000 footer-stripe-1"></div>
    <div class="w-1000 footer-stripe-2"></div>
    <div class="w-1000 footer-stripe-3"></div>
    <div *ngIf="mainFooter" class="tm-footer">
        <div class="container">
            <section class="section-b-space border-b">
                <div class="row">

                    <div class="col-md-7">
                        <div class="row footer-base-det mt-3">
                            <div class="position-absolute position-logo">
                                <img src="https://tmdone-cdn.s3.me-south-1.amazonaws.com/tmweb/images/tm/footer-logo.png" alt="">
                            </div>
                            <div class="col-xxl-6 col-xl-4 col-lg-4 col-md-4">
                              
                                <h6 class="footer-title">TM DONE </h6>
                                <h5 class="detail-txt">Ghala Street, Muscat, Oman, 116</h5>
                            </div>
                            <div class="col-md-3">
                                <h6 class="footer-title">Call Center</h6>
                                <h5 class="detail-txt">+968 24 399953</h5>
                            </div>
                            <div class="col-md-3">
                                <h6 class="footer-title">Email </h6>
                                <h5 class="detail-txt">{{'info@tmdone.com'}}</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="payment-card-bottom">
                            <ul class="d-flex justify-content-md-end justify-content-sm-left justify-content-center mb-0 mt-3">
                                <li>
                                    <a href="https://www.instagram.com/tmdone/?hl=en" target="_blank">
                                        <div class="social-media-div">
                                            <img src="https://tmdone-cdn.s3.me-south-1.amazonaws.com/tmweb/images/tm/icons/instagram.svg" class="img-fluid" alt="">
                                        </div>
                                    </a>
                                </li>&nbsp;&nbsp;&nbsp;&nbsp;
                                <li>
                                    <a href="https://www.linkedin.com/company/tmdoneoman" target="_blank">
                                        <div class="social-media-div">
                                            <img src="https://tmdone-cdn.s3.me-south-1.amazonaws.com/tmweb/images/tm/icons/linkedin.svg" class="img-fluid" alt="">
                                        </div>
                                    </a>
                                </li>&nbsp;&nbsp;&nbsp;&nbsp;
                                <li>
                                    <a href="https://www.tiktok.com/@tmdone" target="_blank">
                                        <div class="social-media-div">
                                            <img src="https://tmdone-cdn.s3.me-south-1.amazonaws.com/tmweb/images/tm/icons/tiktok.svg" class="img-fluid" alt="">
                                        </div>
                                    </a>
                                </li>&nbsp;&nbsp;&nbsp;&nbsp;
                                <li>
                                    <a href="https://twitter.com/tmdone?lang=en" target="_blank">
                                        <div class="social-media-div">
                                            <img src="https://tmdone-cdn.s3.me-south-1.amazonaws.com/tmweb/images/tm/icons/x-icon.svg" class="img-fluid" alt="">
                                        </div>
                                    </a>
                                </li>
                                <!-- <li>
                                    <a href="javascript:void(0)">
                                        <img src="assets/images/icon/visa.png" alt="">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">
                                        <img src="assets/images/icon/mastercard.png" alt="">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">
                                        <img src="assets/images/icon/paypal.png" alt="">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">
                                        <img src="assets/images/icon/american-express.png" alt="">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">
                                        <img src="assets/images/icon/discover.png" alt="">
                                    </a>
                                </li> -->
                            </ul>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    </div>

    <div class="sub-footer  tm-footer">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="footer-end policy-links ">
                        <ul class="ps-0">
                            <li class="me-3"><p><a class="txt-primary" href="">Privacy Policy </a></p></li>
                            <li class="me-3"><p><a class="txt-primary" href="https://docs.tmdone.com/tos.html">Terms and Conditions</a></p></li>
                            <li class="me-3"><p><a class="txt-primary" href="https://docs.tmdone.com/faq.html">FAQ</a></p></li>
                            <li class="me-3"><p><a class="txt-primary" [routerLink]="'/sitemap'">Sitemap</a></p></li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <p class="text-md-end text-center"><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y' }}
                        Copyright, All Rights Reserved TM!DONE</p>

                </div>
            </div>
        </div>
    </div>
</footer>
<!--footer section end -->