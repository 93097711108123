<!-- <ngx-loading-bar></ngx-loading-bar> -->
 @if ((loaders | async)>0) {

     <div class="progress-bar-wrapper">
         <div class="progress-bar" [ngStyle]="{'width.%': (loaders | async)}"></div>
        </div>
    }
  
<router-outlet></router-outlet>
<app-tap-to-top></app-tap-to-top>
<!-- <app-layout-box></app-layout-box> -->