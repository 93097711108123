import { Injectable } from "@angular/core";
import { BaseDataService } from "src/app/shared/services/base-data.service";
import { GoogleMapService } from "src/app/shared/services/google-map-service.service";
import {
  SectorStore,
  SectorStorePaginated,
  SectorStoresReq,
  ProductCategoryVM,
  ProductReqVM,
  ProductItem,
  ProductPaginated
} from "../models/sector.model";
import { Observable } from "rxjs";
import {
  MenuItemOne,
  MenuItemVm,
  VendorType,
} from "../stores/models/menu-items";

@Injectable({
  providedIn: "root",
})
export class SectorStoreService {
  private sectorStoresApiUrl: string = "/stores/web/list";
  private sectorStoresInfoApiUrl: string = "/stores/web/info";
  private menuItemFindApiUrl: string = "/menutem/find";
  private menuItemfindOneApiUrl: string = "/menutem/findOne";
  private ProductCategoryApiUrl: string = "/product/categories"
  private ProductApiUrl: string = "/product"

  constructor(
    private baseDataService: BaseDataService,
    private googleMapService: GoogleMapService
  ) {}

  getSectorStores(sectorStoresReq: SectorStoresReq) {
    // sectorStoresReq.Coordinates =
    // this.googleMapService.getCurrentLocationArray;
    sectorStoresReq.Coordinates =
    ( (sectorStoresReq?.ZoneSlug && sectorStoresReq?.ZoneSlug.length) > 0 ||
     (sectorStoresReq.AreaSlug && sectorStoresReq?.AreaSlug.length )> 0 || 
     (sectorStoresReq.CitySlug && sectorStoresReq?.CitySlug.length )> 0)
        ? []
        : this.googleMapService.getCurrentLocationArray;
    return this.baseDataService.post<SectorStorePaginated>(
      this.sectorStoresApiUrl,
      sectorStoresReq,
      false
    );
  }

  getStoreBySulg(slug: string): Observable<SectorStore> {
    
    var constructedUrl =
      this.sectorStoresInfoApiUrl +
      "?slug=" +
      encodeURIComponent(slug) +
      "&" +
      this.baseDataService.isPickupParameter 

      var locationParameter = "&" +
      this.googleMapService.getLocationParameter();

      if(!this.googleMapService.isDefaultLocation) {
        constructedUrl = constructedUrl + locationParameter;
      }

    return this.baseDataService.post<SectorStore>(constructedUrl, {}, false);
  }

  getMenuItemsSummary(storeId: string): Observable<MenuItemVm> {
    return this.baseDataService.post<MenuItemVm>(
      this.menuItemFindApiUrl + "?storeId=" + storeId,
      {}
    );
  }
  getMenuItemFindOne(
    storeId: string,
    menuItemId: string
  ): Observable<MenuItemOne> {
    return this.baseDataService.post<MenuItemOne>(
      this.menuItemfindOneApiUrl +
        "?storeId=" +
        storeId +
        "&menuItemId=" +
        menuItemId,
      {}
    );
  }
  getProductCategoriesFind(storeId: string): Observable<ProductCategoryVM[]> {
    return this.baseDataService.get(
      this.ProductCategoryApiUrl + "/find" +
        "?storeId=" +
        storeId
    );
  }
  getProductsSummary(productReqObj: ProductReqVM): Observable<ProductItem[]> {
    return this.baseDataService.post<ProductItem[]>(
      this.ProductApiUrl + "/findGroups",
      productReqObj
    );
  }
  getProductsByCategory(productReqObj: ProductReqVM): Observable<ProductPaginated> {
    return this.baseDataService.post<ProductPaginated>(
      this.ProductApiUrl + "/find",
      productReqObj
    );
  }
  getProductFindOne(
    storeId: string,
    menuItemId: string
  ): Observable<MenuItemOne> {
    return this.baseDataService.post<MenuItemOne>(
      this.ProductApiUrl + '/findOne' +
        "?storeId=" +
        storeId +
        "&itemId=" +
        menuItemId,
      {}
    );
  }
}
