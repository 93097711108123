import { Component, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as AOS from 'aos';
import { Router } from '@angular/router';
import { GoogleMapsLoaderService } from './shared/services/google-maps-loader.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  // For Progressbar
  // loaders = this.loader.progress$.pipe(
  //   delay(1000),
  //   withLatestFrom(this.loader.progress$),
  //   map(v => v[1]),
  // );
  loaders = this.loader.progress$.pipe(
    delay(1000), // Optional: Delay to mimic loading delay (you can adjust or remove it)
    withLatestFrom(this.loader.progress$),
    map(v => v[1])
  );
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService, translate: TranslateService,private router:Router,private googleMapsLoaderService:GoogleMapsLoaderService) {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    if (isPlatformBrowser(this.platformId)) {
      translate.setDefaultLang('en');
      translate.addLangs(['en', 'fr']);
    }

    googleMapsLoaderService.load("AIzaSyAS3Vxm8b4zVWe1jg-WUZ_FVd9KYSKYHDI")
  }
ngOnInit(): void {
  //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
  //Add 'implements OnInit' to the class.
  AOS.init();


}
}
