import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-breadcrumb-one',
  templateUrl: './breadcrumb-one.component.html',
  styleUrl: './breadcrumb-one.component.scss'
})
export class BreadcrumbOneComponent {
  @Input() breadcrumbTxt: string = ''
}
