<div class="store-card">
  <div class="img-container position-relative">
    <img
      [src]="getCoverUrl()"
      alt=""
      class="position-absolute overlay-image store-img"
    />
    <div class="img-tranceperent d-flex align-items-end">
      <span class="ms-3 mb-2 text-white"
        ><img
          src="../../../../../assets/images/tm/icons/img_restaurant_delivery_thunder.png"
          alt=""
          srcset=""
        />
        &nbsp;{{ storeData?.DeliveryTime }} | Delivery Fee:
        {{ storeData?.DeliveryFee | number : "1.3-3" }}</span
      >
    </div>
  </div>
  <div class="w-100 ms-2 mb-3">
    <h6 class="mt-1 mb-0 st-name">{{ storeData?.Name }}</h6>
    <p class="mb-0 item-txt">{{ storeData?.Cuisines }}</p>
    <span class="rate-txt"
      ><img
        class="rating-star"
        src="../../../../../assets/images/tm/icons/img_restaurant_ratings.png"
        alt=""
        srcset=""
      />
      &nbsp;{{ storeData?.Rating }} &nbsp;({{ storeData?.RatedCount }})
      @if(storeData.OfferName){
      <span class="spOffer-txt">{{ storeData.OfferName }}</span> }</span
    >
  </div>
</div>
