<footer [ngClass]="class">
    <div class="sub-footer  tm-footer">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="footer-end policy-links ">
                        <ul>
                            <li class="me-3"><p><a class="txt-primary" href="">Privacy Policy </a></p></li>
                            <li class="me-3"><p><a class="txt-primary" href="https://docs.tmdone.com/tos.html">Terms and Conditions</a></p></li>
                            <li class="me-3"><p><a class="txt-primary" href="https://docs.tmdone.com/faq.html">FAQ</a></p></li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <p class="text-md-end text-center copyright-text"><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y' }}
                        Copyright, All Rights Reserved TM!DONE</p>

                </div>
            </div>
        </div>
    </div>
</footer>
<!--footer section end -->