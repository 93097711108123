import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";

const state = {
  checkoutItems: JSON.parse(localStorage["checkoutItems"] || "[]"),
};

@Injectable({
  providedIn: "root",
})
export class OrderService {
  private ordertypeChanged = new BehaviorSubject(null);
  private searchTextChanged = new BehaviorSubject(null);
  constructor(private router: Router) {}

  getOrderTypeChanged():Observable<string>{
    return this.ordertypeChanged.asObservable();
  }
  setOrderTypeChanged(type:string){
    return this.ordertypeChanged.next(type);
  }
  getSearchtextChanged():Observable<string>{
    return this.searchTextChanged.asObservable();
  }
  setSearchTextChanged(searchText:string){
    return this.searchTextChanged.next(searchText);
  }

  // // Get Checkout Items
  // public get checkoutItems(): Observable<any> {
  //   const itemsStream = new Observable((observer) => {
  //     observer.next(state.checkoutItems);
  //     observer.complete();
  //   });
  //   return <Observable<any>>itemsStream;
  // }

  // // Create order
  // public createOrder(product: any, details: any, orderId: any, amount: any) {
  //   var item = {
  //     shippingDetails: details,
  //     product: product,
  //     orderId: orderId,
  //     totalAmount: amount,
  //   };
  //   state.checkoutItems = item;
  //   localStorage.setItem("checkoutItems", JSON.stringify(item));
  //   localStorage.removeItem("cartItems");
  //   this.router.navigate(["/shop/checkout/success", orderId]);
  // }
  // get getOrdertype() {
  //   return localStorage.getItem("orderType") ?? 'DELIVERY';
  // }



}
