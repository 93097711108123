<nav class="navbar navbar-expand-lg navbar-light bg-white border">
    <div class="container nav-container">
      <div class="d-flex align-items-center">
        <a class="me-3 hamburgerIcon navbar-toggler"(click)="toggleCollapse()"
        [attr.aria-expanded]="!isCollapsed"
        aria-label="Toggle navigation">
            <img [src]="Hamburgermenu" alt="Hamburger Menu">
        </a>
        <a class="navbar-brand" href="#">
            <img [src]="themeLogo" alt="Logo">
        </a>
      </div>
      <div class="collapse" id="collapseExample">
        <div class="card card-body">
          Some placeholder content for the collapse component. This panel is hidden by default but revealed when the user activates the relevant trigger.
        </div>
      </div>
        <div [class.collapse]="isCollapsed" class="collapse navbar-collapse">
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a class="nav-link" href="/about-us">About Us</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="/contact-us">Contact Us</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="/join-us">Join Us</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="https://docs.tmdone.com/faq.html">FAQ</a>
                </li>
                <li class="nav-item d-lg-none d-md-bock">
                    <a class="nav-link" routerLink="/login">Sign Up or Login</a>
                </li>
            </ul>
        </div>
        <div class="d-flex d-lg-block d-md-none d-sm-none d-none">
          <button class="btn primary-btn" routerLink="/login">Sign Up or Login</button>
        </div>
    </div>
</nav>