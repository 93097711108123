import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  NgZone,
  ViewChild,
} from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { LocationSearchSharedService } from "src/app/home/services/locations/location-search-shared.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { GoogleMapService } from "src/app/shared/services/google-map-service.service";
import { Observable, pipe, ReplaySubject, takeUntil } from "rxjs";
import { Location } from "src/app/shared/data/location-search";
import { GoogleMap } from "@angular/google-maps";
@Component({
  selector: "app-location-modal",

  templateUrl: "./location-modal.component.html",
  styleUrl: "./location-modal.component.scss",
})
export class LocationModalComponent {
  @ViewChild("template", { static: true }) template: any;
  @ViewChild("searchInput", { static: false }) searchInput:
    | ElementRef
    | undefined;
  map: google.maps.Map;
  modalRef: BsModalRef | undefined;
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);
  isMapInitialized = false;
  isModalVisible = false;
  currentLocation: Location;
  center: google.maps.LatLng = new google.maps.LatLng(-34.397, 150.644);
  zoom = 17;
  options: google.maps.MapOptions = null;
  markerIcon: string = "";
  selectedLocation: Location;
  locatiionSearchList: Location[] = [];
  isFetching: boolean = false;

  isShowResults: boolean = false;
  searchText: string = "";
  highlightedIndex = 0;
  private marker: google.maps.Marker | undefined;

  constructor(
    private locationSearchSharedService: LocationSearchSharedService,
    private modalService: BsModalService,
    private mapService: GoogleMapService,
    private cdr: ChangeDetectorRef, // Inject ChangeDetectorRef
    private zone: NgZone // Inject NgZone
  ) {
    this.locationSearchSharedService
      .getLocationModal()
      .pipe(takeUntil(this.destroy))
      .subscribe((res) => {
        if (res && res == "open") {
          this.showModal();
        }
      });
    this.markerIcon = "assets/images/tm/ic_map_pin.png";
  }
  handleKeyDown(event: KeyboardEvent) {
    const { key } = event;

    if (key === "ArrowDown") {
      this.highlightedIndex = Math.min(
        this.highlightedIndex + 1,
        this.locatiionSearchList.length - 1
      );
      event.preventDefault();
    } else if (key === "ArrowUp") {
      this.highlightedIndex = Math.max(this.highlightedIndex - 1, 0);
      event.preventDefault();
    } else if (key === "Enter" && this.highlightedIndex !== -1) {
      this.selectLocation(this.locatiionSearchList[this.highlightedIndex]);
    }
  }

  private showModal() {
    this.currentLocation = this.mapService.getCurrentLocationValue();
    this.options = this.mapService.getDefaultMapOptions();

    if (!this.currentLocation) {
      this.mapService.loadCurrentLocation();
    }
    this.center = new google.maps.LatLng(
      this.currentLocation.latitude,
      this.currentLocation.longitude
    );
    this.zoom = this.options.zoom;
    if (this.currentLocation?.text) {
      this.zoom = 18;
      this.options.zoom = 18;
    }

    this.modalRef = this.modalService.show(this.template, {
      class: "modal-md modal-dialog-centered",
    });
  }
  initializeAutocomplete() {
    if (this.searchInput) {
      this.mapService.initializeAutocomplete(this.searchInput.nativeElement);
    }
  }
  ngAfterViewInit(): void {
    this.initializeAutocomplete();
  }

  mapInit() {
    // if (this.mapElement && !this.mapService.getMap()) {
    //   console.log('Initializing map with element:', this.mapElement.nativeElement);
    //   this.mapService.initMap(this.mapElement.nativeElement);
    //   this.isMapInitialized = true;
    // } else {
    //   console.error('Map element is not defined or map is already initialized.');
    // }
  }

  closeModal() {
    this.modalRef?.hide();
    this.isMapInitialized = false; // Reset the flag when closing the modal
  }
  onMapDrag(event: any): void {
    // Optionally, you can update the center of the map or perform other actions
  }

  getMarkerIcon() {
    let imgTag = document.createElement("img");
    imgTag.src = this.markerIcon;
    return imgTag;
  }
  onMapDragEnd(): void {
    // Get the new center of the map
  }

  moveMap(event: google.maps.MapMouseEvent) {
    var coords = event.latLng.toJSON();
    this.center = new google.maps.LatLng(coords.lat, coords.lng);
  }

  getInitMap(map: google.maps.Map) {
    this.map = map;

    this.map?.addListener("drag", () => {
      const newCenter = this.map?.getCenter();
      if (newCenter) {
        this.center = newCenter;
        this.marker.setPosition(newCenter);
        this.cdr.detectChanges();
      }
    });

    // this.map?.addListener("dragend", () => {

    // });

    // this.map?.addListener("zoom_changed", () => {

    // });
  }

  zoomChanged() {
    const newZoom = this.map?.getZoom();
    const newCenter = this.map?.getCenter();
    this.marker.setPosition(newCenter);
    if (newZoom !== null) {
      this.zoom = newZoom;
      if (this.zoom >= 18) {
        this.isFetching = true;
        this.getDraggedLocation(newCenter.lat(), newCenter.lng())
          .pipe(takeUntil(this.destroy))
          .subscribe((formatted_address) => {
            this.currentLocation = {
              latitude: newCenter.lat(),
              longitude: newCenter.lng(),
              text: formatted_address,
            };

            this.isFetching = false;
            this.cdr.detectChanges();
          });

        // this.isFetching = false;
      } else {
        this.currentLocation = null;
      }
    }
  }
  mapDragEnd() {
    const newCenter = this.map?.getCenter();
    if (newCenter) {
      // this.center = newCenter;
      // this.marker.setPosition(newCenter);

      if (this.zoom >= 18) {
        this.getDraggedLocation(newCenter.lat(), newCenter.lng())
          .pipe(takeUntil(this.destroy))
          .subscribe((formatted_address) => {
            this.currentLocation = {
              latitude: newCenter.lat(),
              longitude: newCenter.lng(),
              text: formatted_address,
            };

            this.isFetching = false;
            this.cdr.detectChanges();
          });
      }
    }
  }
  markerInIt(marker: google.maps.Marker) {
    this.marker = marker;
    this.marker.setPosition(this.center);
    const icon = {
      url: this.markerIcon, // url
      scaledSize: new google.maps.Size(30, 45), // scaled size
      origin: new google.maps.Point(0, 0), // origin
      anchor: new google.maps.Point(0, 0), // anchor
    };
    this.marker.setIcon(icon);
  }

  confirmLocation() {
    this.mapService.saveLocation(this.currentLocation);
  }

  searchLocation(event) {
    var searchText = event.target.value;
    if (searchText.length == 0) {
      this.isShowResults = false;
    }
    this.mapService
      .autocompleteLocation(searchText)
      .pipe(takeUntil(this.destroy))
      .subscribe((predictions) => {
        this.isShowResults = predictions.length > 0;
        this.locatiionSearchList = predictions.map((prediction) => ({
          text: prediction.description,
          primaryText: prediction.structured_formatting.main_text,
          secondaryText: prediction.structured_formatting.secondary_text,
          latitude: 0, // Will be filled later with actual lat/lng if needed
          longitude: 0, // Will be filled later with actual lat/lng if needed
        }));
      });
  }

  selectLocation(location: Location) {
    this.isShowResults = false;
    this.isFetching = true;
    this.searchText = "";
    this.currentLocation.text = location?.text;
    this.mapService
      .searchLocation(location.text, this.map)
      .pipe(takeUntil(this.destroy))
      .subscribe((res) => {
        // var coords = new google.maps.LatLng(res.latitude, res.longitude);
        // this.currentLocation = res;
        // this.currentLocation.text = location.text;
        // this.map.setZoom(18);
        // this.map.setCenter(coords);
        // this.marker.setPosition(coords);
        // this.isFetching = false;
        // this.locatiionSearchList = [];
        // this.cdr.detectChanges();
        var coords = new google.maps.LatLng(res.latitude, res.longitude);

        this.zone.run(() => {
          this.currentLocation.latitude = res.latitude;
          (this.currentLocation.longitude = res.longitude),
            (this.currentLocation.primaryText = res.primaryText);
          this.currentLocation.secondaryText = res.secondaryText;
          this.currentLocation.text = res.text;
          // other updates...
          this.cdr.detectChanges();
        });

        this.map.setZoom(17);
        this.map.setCenter(coords);
        this.marker.setPosition(coords);
        this.isFetching = false;
        this.locatiionSearchList = [];
        this.cdr.detectChanges();
      });
  }

  getDraggedLocation(lat: number, lng: number): Observable<string> {
    return this.mapService.getLocationDetails(lat, lng);
  }
}

//   setCurrentLocation() {
//     this.mapService.getCurrentLocation().pipe(takeUntil(this.destroy)).subscribe((res) => {
// // this.center=res
//     });
//   }
