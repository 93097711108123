<div class="area-card p-3 mt-3">
  <div class="row">
    <div class="col-10 d-flex align-items-center">
      <h3 class="title fs-6 text-capitalize mb-0">
        {{ title }}
      </h3>
      <!-- <p class="description fs-6 text-white">
        {{ description }}
      </p> -->
    </div>
    <div class="col-2 d-flex align-items-center justify-content-end">
      <div class="btn-next">
        <a href="/{{ url }}">
          <img
            src="assets/images/tm/icons/next-icon.svg"
            alt=""
            class="img-fluid"
          />
        </a>
      </div>
    </div>
  </div>
</div>
