<nav class="navbar navbar-expand-lg navbar-light bg-white border">
    <div class="container">
      <div class="d-flex align-items-center">
        <a class="navbar-brand" href="#">
            <img [src]="themeLogo" alt="Logo">
        </a>
      </div>
        <!-- <div class="collapse navbar-collapse">
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a class="nav-link" href="/about-us">About Us</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="/contact-us">Contact Us</a>
                </li>
            </ul>
        </div> -->
    </div>
</nav>