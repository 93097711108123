import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsLoaderService {

  private isLoaded = false;
  private loadPromise: Promise<void>;

  load(apiKey: string): Promise<void> {
    if (this.isLoaded) {
      return Promise.resolve(); // Script already loaded
    }

    if (!this.loadPromise) {
      this.loadPromise = new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
        script.async = true;
        script.defer = true;

        script.onload = () => {
          this.isLoaded = true;
          resolve();
        };

        script.onerror = (error) => reject(error);

        document.body.appendChild(script);
      });
    }

    return this.loadPromise;
  }
}
