<div class="conatiner px-3 pt-4 {{ isMobile ? '' : 'filter-container' }} ">
  @if (this.filterType=='sector') {

  <div class="row d-flex justify-content-end">
    <div class="col-xxl-8 col-xl-9 col-lg-12 col-md-12">
      <h5>All {{ sectorValue?.Name }}</h5>
    </div>
  </div>

  <div class="row d-flex justify-content-end">
    <div class="col-xxl-8 col-xl-9 col-lg-12 col-md-12">
      <button
        type="button"
        class="btn w-100 font-size-md-12 font-size-lg-16 rest-btn text-capitalize"
        (click)="resetFilter()"
      >
      <img src="https://tmdone-cdn.s3.me-south-1.amazonaws.com/tmweb/images/tm/icons/in_filter_reset.png" alt="">
        Reset&nbsp;&nbsp;
      </button>
    </div>
  </div>

  @if(!isAttrLoading) {

  <div [formGroup]="filterForm" class="mt-3">
    <div class="row mt-2 d-flex justify-content-end">
      @for(attr of attributesList; track $index) {
      <div class="col-xxl-8 col-xl-9 col-lg-12 col-md-12">
        <div class="form-check mb-2">
          <input
            class="form-check-input filter-check-input"
            type="radio"
            [value]="attr?.Code"
            [id]="'att-'+ $index"
            [formControlName]="'selectedAttributes'"
          />
          <label
            class="form-check-label font-size-md-12 font-size-lg-16"
            [for]="'att-'+ $index"
          >
            {{ attr?.Name }}
          </label>
        </div>
      </div>
      }
    </div>
  </div>

  } @else{

  <div class="row mt-2 d-flex justify-content-end mt-3">
    <div class="col-xxl-8 col-xl-9 col-lg-12 col-md-12"></div>
  </div>
  <app-skeleton-filter-checks
    [skeletonItems]="[1, 2, 3, 4, 5, 6]"
  ></app-skeleton-filter-checks>

  } @if(!isSortAttrLoading) {

  <div class="row mt-2 d-flex justify-content-end mt-3">
    <div class="col-xxl-8 col-xl-9 col-lg-12 col-md-12">
      <h6>Sort By</h6>
    </div>
  </div>

  <div class="row mt-2" [formGroup]="filterForm">
    <div>
      @for(sortFilter of sortAttributesList; track $index) {

      <div class="row d-flex justify-content-end">
        <div class="col-xxl-8 col-xl-9 col-lg-12 col-md-12">
          <div class="form-check mb-2">
            <input
              class="form-check-input filter-check-input"
              type="radio"
              formControlName="sortAttr"
              id="sort-{{ $index }}"
              [value]="sortFilter.Code"
            />
            <label
              class="form-check-label font-size-md-12 font-size-lg-16"
              for="sort-{{ $index }}"
            >
              {{ sortFilter.Name }}
            </label>
          </div>
        </div>
      </div>

      }
    </div>
  </div>

  } @else {

  <div class="row mt-2 d-flex justify-content-end mt-3">
    <div class="col-xxl-8 col-xl-9 col-lg-12 col-md-12">
      <h6>Sort By</h6>
    </div>
  </div>
  <app-skeleton-filter-checks
    [skeletonItems]="[1, 2, 3, 4, 5, 6]"
  ></app-skeleton-filter-checks>

  } @if (selectedSector==sectorSlugs.RESTAURANTS) { @if(!isCuisinesLoading) {
  <div class="row mt-2 d-flex justify-content-end mt-3">
    <div class="col-xxl-8 col-xl-9 col-lg-12 col-md-12">
      <h6>Cuisines</h6>
    </div>
  </div>
  <div [formGroup]="filterForm">
    <div class="row mt-2 d-flex justify-content-end" formArrayName="cuisines">
      @for(cuisine of cuisinesList; track $index) {

      <div class="col-xxl-8 col-xl-9 col-lg-12 col-md-12">
        <div class="form-check mb-2">
          <a [routerLink]="generateCuisineUrl(cuisine.Slug)">
            <input
              class="form-check-input filter-check-input"
              type="checkbox"
              [id]="'cuisine-'+ $index"
              [formControlName]="$index"
            />
            <label
              class="form-check-label font-size-md-12 font-size-lg-16"
              [for]="'cuisine-'+ $index"
            >
              {{ cuisine.Name }}
            </label>
          </a>
        </div>
      </div>

      }
    </div>
  </div>

  } @else {

  <div class="row mt-2 d-flex justify-content-end mt-3">
    <div class="col-xxl-8 col-xl-9 col-lg-12 col-md-12">
      <h6>Cuisines</h6>
    </div>
  </div>
  <app-skeleton-filter-checks
    [skeletonItems]="[1, 2, 3, 4, 5, 6]"
  ></app-skeleton-filter-checks>

  } } @if (selectedSector!=sectorSlugs.RESTAURANTS && this.selectedSector !=
  this.sectorSlugs.CHARITIES) { @if(!isVendorTypeLoading) {

  <div class="row mt-2 d-flex justify-content-end mt-3">
    <div class="col-xxl-8 col-xl-9 col-lg-12 col-md-12">
      <h6>Store Types</h6>
    </div>
  </div>
  <div [formGroup]="filterForm">
    <div
      class="row mt-2 d-flex justify-content-end"
      formArrayName="vendorTypes"
    >
      @for(vendorType of vendorTypeList; track $index) {
      <div class="col-xxl-8 col-xl-9 col-lg-12 col-md-12">
        <div class="form-check mb-2">
          <input
            class="form-check-input filter-check-input"
            type="checkbox"
            [id]="'v-type-'+ $index"
            [formControlName]="$index"
          />
          <label
            class="form-check-label font-size-md-12 font-size-lg-16"
            [for]="'v-type-'+ $index"
          >
            {{ vendorType?.Name }}
          </label>
        </div>
      </div>

      }
    </div>
  </div>

  } @else {

  <div class="row mt-2 d-flex justify-content-end mt-3">
    <div class="col-xxl-8 col-xl-9 col-lg-12 col-md-12">
      <h6>Store Types</h6>
    </div>
  </div>
  <app-skeleton-filter-checks
    [skeletonItems]="[1, 2, 3, 4, 5, 6]"
  ></app-skeleton-filter-checks>

  } } } @else {

  <!-- store  -->

  @if(menuCategories?.length>0 ){

  <div class="row d-flex justify-content-end">
    <div class="col-xxl-8 col-xl-9 col-lg-12 col-md-12">
      <h4>
        {{
          selectedSector == sectorSlugs.RESTAURANTS
            ? "Menu Items"
            : "Menu Categories"
        }}
      </h4>

      <div>
        <div class="row mt-3 mb-5" [formGroup]="filterForm">
          @for(menuCategory of menuCategories; track $index) {

          <div class="row d-flex menuCategory">
            <div class="col-xxl-8 col-xl-9 col-lg-12 col-md-12">
              <div
                class="form-check mb-2 menuCategory-el  {{
                  filterForm?.get('menuCategory').value == menuCategory.Code || menuCategories.length
                    ? 'p-0'
                    : ''
                }}"
                (click)="menuCategoryChecked(menuCategory.Code)"
              >
                <div
                  class="form-check-label category-lbl font-size-md-12 font-size-lg-16"
                >
                  {{ menuCategory.Name }}
                </div>
              </div>
            </div>
          </div>

          }
        </div>
      </div>
    </div>
  </div>

  } @else {

  <app-skeleton-filter-checks
    [skeletonItems]="[1, 2, 3, 4, 5, 6]"
  ></app-skeleton-filter-checks>

  } }
  
</div>
