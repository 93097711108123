import { Injectable } from "@angular/core";
import { GoogleMapService } from "./google-map-service.service";
import { BaseDataService } from "./base-data.service";
import { Location } from "../data/location-search";
import { VendorType } from "src/app/home/sectors/stores/models/menu-items";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AttributeService {
  private sortAttrsApiUrl: string = "/attributes/sortattribs";
  private attrsAllApiUrl: string = "/attributes/find";
  currrentLocation: Location;
  private vendortypeApiUrl: string = "vendorTypes/all";
  constructor(
    private baseDataService: BaseDataService,
    private googleMapService: GoogleMapService
  ) {
    this.currrentLocation = googleMapService.getCurrentLocationValue();
  }

  getSortAttributes() {
    return this.baseDataService.get(this.sortAttrsApiUrl + "?"+this.googleMapService.getLocationParameter(),true);
  }
  getAllAttributes() {
    return this.baseDataService.get(this.attrsAllApiUrl + "?"+this.baseDataService.isPickupParameter,true);
  }
  getAttributes(sectorCode:string) {
    return this.baseDataService.get(this.attrsAllApiUrl + "?"+this.baseDataService.isPickupParameter+'&sectorCode='+sectorCode+'&'+this.googleMapService.getLocationParameter(),false,true);
  }

  getVendortypes(sectorId:string):Observable<VendorType[]>{
    return this.baseDataService.get<VendorType[]>(this.vendortypeApiUrl+'?sectorId='+sectorId)
   }
}
