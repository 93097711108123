import { Injectable } from "@angular/core";
import { BaseDataService } from "src/app/shared/services/base-data.service";
import { SectorBaseModel } from "../models/sector.model";
import { catchError, map, of, tap } from "rxjs";
import { GoogleMapService } from "src/app/shared/services/google-map-service.service";
import { Location } from "src/app/shared/data/location-search";
import { SECTOR_DATA } from "src/app/shared/data/sectors";

@Injectable({
  providedIn: "root",
})
export class SectorService {
  private sectorsApiUrl: string = "sectors/fetch";
  private sectorsList: SectorBaseModel[] = [];
  constructor(private baseDataService: BaseDataService,private googleLocationService:GoogleMapService) {
    this.getAllSectors();
  }

  // getAllSectors() {
  //   return this.baseDataService.get<SectorBaseModel[]>(this.sectorsApiUrl+'?appCode='+this.baseDataService.APPCODE, true);
  // }
  getAllSectors() {
    const currentlocation: Location = this.googleLocationService.getCurrentLocationValue();
    const localStorageKey = "sectorsData";
  
    // Check if sectors are in localStorage
    const storedSectors = localStorage.getItem(localStorageKey);
  
    if (storedSectors) {
      // Return the stored sectors from localStorage as an Observable
      return of(JSON.parse(storedSectors));
    } else {
      
    if (!currentlocation) {
      return of(SECTOR_DATA);
    }
      // Fetch the sectors from the API if not in localStorage
      return this.baseDataService
        .get<SectorBaseModel[]>(
          this.sectorsApiUrl + "?appCode=" + this.baseDataService.APPCODE + "&latitude=" + currentlocation.latitude + "&longitude=" + currentlocation.longitude,
          true
        )
        .pipe(
          tap((sectors) => {
            // Save sectors to localStorage
            this.sectorsList = sectors;
            localStorage.setItem(localStorageKey, JSON.stringify(sectors));
          }),
          catchError((error) => {
            // If there is an error with the API, fallback to the static SECTOR_DATA
            console.error('API request failed. Falling back to static sector data.', error);
            return of(SECTOR_DATA); // Fallback to static data
          })
        );
    }
  }
  
  getSectorsValue(): SectorBaseModel[] {
    const localStorageKey = "sectorsData";
    const data = localStorage.getItem(localStorageKey);
    if (data) {
      return JSON.parse(data);
    }
  
    // If localStorage is empty, fallback to static SECTOR_DATA
    return SECTOR_DATA;
  }

  sectorExists(sector: string) : boolean {

    // console.log(sector,"log3");
    
    return this.getSectorsValue().some(sectorValue=>sectorValue?.Slug?.toLowerCase() ==  sector?.toLocaleLowerCase())
  }


  sectorValue(sector: string): SectorBaseModel {
   return this.sectorExists(sector) ? this.getSectorsValue().find(sectorValue=>sectorValue?.Slug?.toLowerCase() ==  sector?.toLocaleLowerCase()) : null
  }
}
