export const SECTOR_SLUGS = {
  RESTAURANTS: "restaurants",
  GROCERIES: "groceries",
  STORES: "stores",
  FLOWERS: "flowers",
  CHARITIES: "charities",
  TM_STORE: "tm-store",
};

export const SECTOR_CODES = {
  FOOD: "FOOD",
  SERVICE: "service",
  GROC: "GROC",
  FLOWER: "FLOWER",
  CHARITY: "CHARITY",
  MART: "MART",
};

export const ORDER_TYPE = {
  DELIVERY: "DELIVERY",
  PICKUP: "PICKUP",
};

export const BANNER_AREA_CODES = {
  DEALS: "Deals",
};

export const SECTOR_DESCRIPTIONS = {
  restaurants: "Explore a wide variety of culinary delights from local favorites to international cuisines. Whether it's breakfast, lunch, or dinner, our platform connects you with top restaurants, making it easy for you to satisfy your cravings. Enjoy hassle-free ordering and prompt delivery at your convenience.",
  groceries: "Our grocery delivery service brings all your household essentials straight to your door. From fresh fruits and vegetables to everyday pantry items, we’ve got you covered. Skip the queues and have everything delivered without any stress, making shopping more convenient than ever.",
  stores: "TM Store is your one-stop-shop for everything you need! Discover a wide range of items, from beauty products, skincare, and wellness items to camping gear, electronics, and household essentials. Browse the extensive selection and shop with ease for the things that matter most to you.",
  flowers: "Brighten up any occasion with our beautiful floral arrangements. Whether it's to celebrate a special event, show appreciation, or simply bring some color into your space, our fresh flowers are available for delivery with just a few clicks. Choose from a wide range of bouquets and let us help make someone's day.",
  donations: "Giving back to the community has never been easier. Our platform allows you to make donations to a variety of local charities and non-profit organizations directly through the app. Join us in making a difference by supporting those in need, whether it's for humanitarian efforts, education, or community programs.",
 // "tm-store": "TM Store has it all! From high-quality beauty products, blooms, gadgets, and fitness gear, to everything you need for a camping trip, our TM Store is designed to cater to your diverse needs. Shop conveniently and enjoy fast delivery on all your favorite items.",
  electronics: "Get your hands on the latest electronic gadgets and devices without stepping out of your home. Our platform offers a wide selection of the best electronics, including headphones, speakers, smartphones, and more. Stay updated with the latest technology and enjoy quick delivery services straight to your door."
};