import { Component, OnInit, Input, HostListener } from '@angular/core';

@Component({
  selector: 'app-header-five',
  templateUrl: './header-five.component.html',
  styleUrl: './header-five.component.scss'
})
export class HeaderFiveComponent {
  @Input() class: string;
  @Input() themeLogo: string = 'https://tmdone-cdn.s3.me-south-1.amazonaws.com/tmweb/images/tm/logo.png'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false
  Hamburgermenu: string = "https://tmdone-cdn.s3.me-south-1.amazonaws.com/tmweb/images/tm/icons/hamburger-menu.png";
  
  public stick: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  	if (number >= 150 && window.innerWidth > 400) { 
  	  this.stick = true;
  	} else {
  	  this.stick = false;
  	}
  }
}
