import { Component, Input, input } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import {
  distinctUntilChanged,
  ReplaySubject,
  Subscription,
  takeUntil,
} from "rxjs";
import { AttributeService } from "../../services/attributes.service";
import { Attribute, SortAttributes } from "../../data/sort-attributes.model";
import { CuisineService } from "../../services/cuisine.service";
import { Cuisine } from "../../data/cuisine";
import { SECTOR_SLUGS } from "../../data/constants";
import { SectorService } from "src/app/home/sectors/services/sector.service";
import { SectorBaseModel } from "src/app/home/sectors/models/sector.model";
import {
  MenuCategory,
  VendorType,
} from "src/app/home/sectors/stores/models/menu-items";
import { OrderService } from "../../services/order.service";
import { ActivatedRoute } from "@angular/router";
import { log } from "console";

@Component({
  selector: "app-common-filter",
  // standalone: true,
  // imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: "./common-filter.component.html",
  styleUrl: "./common-filter.component.scss",
})
export class CommonFilterComponent {
  @Input() filterType: string = "";
  @Input() selectedSector: string = "";
  @Input() selectedArea: string | null = "";
  @Input() selectedCity: string | null = "";
  @Input() selectedZone: string | null = "";
  @Input() selectedCuisine: string | null = "";
  @Input() isMobile: boolean = false;
  @Input() menuCategories: MenuCategory[] = [];
  @Input() filterForm: FormGroup = {} as FormGroup;

  isAttrLoading = false;
  isSortAttrLoading = false;
  isCuisinesLoading = false;
  isVendorTypeLoading = false;

  sectorSlugs = SECTOR_SLUGS;
  sectorValue: SectorBaseModel;
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);

  cuisinesList: Cuisine[] = [];
  attributesList: Attribute[] = [];
  vendorTypeList: VendorType[] = [];
  sortAttributesList: SortAttributes[] = [];

  private cuisinesChangesSubscription: Subscription | null = null;
  private vendorTypesChangesSubscription: Subscription | null = null;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private orderService: OrderService,
    private sectorService: SectorService,
    private attrService: AttributeService,
    private cuisineService: CuisineService
  ) {
    this.orderTypeChanges();
  }

  ngOnInit(): void {
    this.sectorChanges();
    this.filterForm.get("selectedSector").valueChanges.subscribe((res) => {
      this.selectedSector = res;
      this.sectorChanges();
    });
  }

  sectorChanges() {
    if (this.selectedSector) {
      this.sectorValue = this.sectorService.sectorValue(this.selectedSector);

      if (this.filterType == "sector") {
        this.getSectorChanges();
        this.getSortAttrs();
        this.addSortControlToForm();
        this.attributesInit();

        if (this.selectedSector == this.sectorSlugs.RESTAURANTS) {
          this.cuisinesInit();
        } else {
          if (this.selectedSector != this.sectorSlugs.GROCERIES) {
            // this.vendorTypesInit();
          }
          if (this.selectedSector != this.sectorSlugs.CHARITIES) {
            this.vendorTypesInit();
          }
        }
      }
    }
  }

  attributesInit() {
    this.attributesList = [];
    this.getAllAttrs();
  }

  cuisinesInit() {
    this.addCuisinesControlToForm();
    this.addSelectedCuisinesControl();
    this.getAllCuisines();
    this.getCuisinesChanges();
  }

  vendorTypesInit() {
    this.addVendorTypesControl();
    this.addvendorTypesControlToForm();
    this.getAllVendortypes();
    this.getVendorTypesChanges();
  }

  getSectorChanges() {
    this.filterForm.get("selectedSector")?.valueChanges.subscribe((res) => {
      this.sectorValue = this.sectorService.sectorValue(res);
      this.attributesInit();
      this.getSortAttrs();
    });
  }

  addSortControlToForm() {
    if (!this.filterForm.contains("sortAttr")) {
      this.filterForm.addControl("sortAttr", new FormControl(""));
    }
  }

  getSortAttrs() {
    this.isSortAttrLoading = true;
    this.attrService
      .getSortAttributes()
      .pipe(takeUntil(this.destroy))
      .subscribe((res: SortAttributes[]) => {
        this.sortAttributesList = res;
        this.isSortAttrLoading = false;
      });
  }

  getAllAttrs() {
    this.isAttrLoading = true;
    this.attrService
      .getAttributes(this.sectorValue?.Code)
      .pipe(takeUntil(this.destroy))
      .subscribe((res: Attribute[]) => {
        this.attributesList = res;

        this.isAttrLoading = false;

        this.addSelectedAttributesControl();
      });
  }

  getAllVendortypes() {
    this.isVendorTypeLoading = true;
    this.attrService
      .getVendortypes(this.sectorValue?.Id)
      .pipe(takeUntil(this.destroy))
      .subscribe((res: VendorType[]) => {
        this.vendorTypeList = res;

        this.isVendorTypeLoading = false;

        this.populateVendorTypesFormArray();
      });
  }

  getAllCuisines() {
    this.isCuisinesLoading = true;
    this.cuisineService
      .getAllCuisine()
      .pipe(takeUntil(this.destroy))
      .subscribe((res: Cuisine[]) => {
        this.cuisinesList = res;
        this.populateCuisineFormArray();
        this.isCuisinesLoading = false;
      });
  }

  getVendorTypesChanges() {
    this.vendorTypesChangesSubscription = this.filterForm
      .get("vendorTypes")
      ?.valueChanges.pipe(distinctUntilChanged())
      .subscribe(() => {
        const selectedVendorTypes = this.getSelectedVendorTypes();

        this.filterForm
          .get("selectedVendorTypes")
          ?.setValue(selectedVendorTypes);
      });
  }

  getSelectedVendorTypes(): string[] {
    const vendorTypesFormArray = this.filterForm.get(
      "vendorTypes"
    ) as FormArray;

    const selectedCuisines = vendorTypesFormArray.controls
      .map((control, i) =>
        control.value ? this.vendorTypeList[i]?.Code : null
      )
      .filter(Boolean);

    return selectedCuisines;
  }

  getCuisinesChanges() {
    this.cuisinesChangesSubscription = this.filterForm
      .get("cuisines")
      ?.valueChanges.pipe(distinctUntilChanged())
      .subscribe(() => {
        const selectedCuisines = this.getSelectedCuisines();
        this.filterForm.get("selectedCuisines")?.setValue(selectedCuisines);
      });
  }

  getSelectedCuisines(): string[] {
    const cuisinesFormArray = this.filterForm.get("cuisines") as FormArray;

    const selectedCuisines = cuisinesFormArray.controls
      .map((control, i) => (control.value ? this.cuisinesList[i]?.Slug : null))
      .filter(Boolean);

    return selectedCuisines;
  }

  addvendorTypesControlToForm() {
    if (!this.filterForm.contains("vendorTypes")) {
      this.filterForm.addControl("vendorTypes", new FormArray([]));
    }
  }

  addVendorTypesControl() {
    if (!this.filterForm.contains("selectedVendorTypes")) {
      this.filterForm.addControl("selectedVendorTypes", new FormControl([]));
    }
  }

  addAttrControlToForm() {
    if (!this.filterForm.contains("attrs")) {
      this.filterForm.addControl("attrs", new FormArray([]));
    }
  }

  addSelectedAttributesControl() {
    if (!this.filterForm.contains("selectedAttributes")) {
      this.filterForm.addControl("selectedAttributes", new FormControl(""));
    }
  }

  addCuisinesControlToForm() {
    if (!this.filterForm.contains("cuisines")) {
      this.filterForm.addControl("cuisines", new FormArray([]));
    }
  }

  addSelectedCuisinesControl() {
    if (!this.filterForm.contains("selectedCuisines")) {
      this.filterForm.addControl("selectedCuisines", new FormControl([]));
    }
  }

  menuCategoryChecked(value: string) {
    this.filterForm?.get("menuCategory").setValue(value);
  }

  populateCuisineFormArray() {
    const cuisinesFormArray = this.filterForm.get("cuisines") as FormArray;
    this.unsubscribeFromCuisinesChanges();
    cuisinesFormArray.clear();
    this.cuisinesList.forEach((cs) => {
      if (cs.Slug == this.selectedCuisine) {
        cuisinesFormArray.push(new FormControl(true));
      } else {
        cuisinesFormArray.push(new FormControl(false));
      }
    });

    this.getCuisinesChanges();
  }

  populateVendorTypesFormArray() {
    const vendorTypesFormArray = this.filterForm.get(
      "vendorTypes"
    ) as FormArray;
    this.unsubscribeFromCuisinesChanges();
    vendorTypesFormArray.clear();
    this.vendorTypeList.forEach(() => {
      vendorTypesFormArray.push(new FormControl(false));
    });
    this.getVendorTypesChanges();
  }

  ngOnDestroy(): void {
    this.destroy.next(null);
  }

  orderTypeChanges() {
    this.orderService
      .getOrderTypeChanged()
      .pipe(takeUntil(this.destroy))
      .subscribe((res) => {
        if (this.filterType == "sector") {
          this.attributesInit();
        }
      });
  }

  generateCuisineUrl(cuisine) {
    var url = "/";

    if (this.selectedSector) {
      url += this.selectedSector;
    }

    if (this.selectedZone && this.selectedZone.length > 0) {
      url += "/z/" + this.selectedZone;
    } else if (this.selectedArea && this.selectedArea.length > 0) {
      url += "/a/" + this.selectedArea;
    } else if (this.selectedCity && this.selectedCity.length > 0) {
      url += "/c/" + this.selectedCity;
    }

    return url + "/cs/" + cuisine;
  }

  resetFilter() {
    this.filterForm.patchValue({
      sortAttr: "",
      selectedVendorTypes: [],
      selectedCuisines: [],
      selectedAttributes: "",
      menuCategory: "",
    });

    if (this.selectedSector == this.sectorSlugs.RESTAURANTS) {
      this.resetCuisineFormArray();
    }

    if (
      this.selectedSector != this.sectorSlugs.RESTAURANTS ||
      this.selectedSector != this.sectorSlugs.CHARITIES
    ) {
      this.resetVendorTypesFormArray();
    }
  }

  resetCuisineFormArray() {
    const cuisinesFormArray = this.filterForm.get("cuisines") as FormArray;
    this.unsubscribeFromCuisinesChanges();

    cuisinesFormArray.controls.forEach((control) => control.setValue(false));
    this.getCuisinesChanges();
  }

  resetVendorTypesFormArray() {
    const vendorTypesFormArray = this.filterForm.get(
      "vendorTypes"
    ) as FormArray;
    this.unsubscribeFromVendorTypesChanges();

    vendorTypesFormArray?.controls?.forEach((control) => control.setValue(false));
    this.getVendorTypesChanges();
  }

  unsubscribeFromCuisinesChanges() {
    if (this.cuisinesChangesSubscription) {
      this.cuisinesChangesSubscription.unsubscribe();
      this.cuisinesChangesSubscription = null;
    }
  }

  unsubscribeFromVendorTypesChanges() {
    if (this.vendorTypesChangesSubscription) {
      this.vendorTypesChangesSubscription.unsubscribe();
      this.vendorTypesChangesSubscription = null;
    }
  }
}
