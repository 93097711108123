/// <reference types="@types/google.maps" />
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, ReplaySubject, Subject } from "rxjs";
import { Location } from "../data/location-search";
import { setDefaultResultOrder } from "dns";

@Injectable({
  providedIn: "root",
})
export class GoogleMapService {
  private currentLocationSubject: BehaviorSubject<Location | null> =
    new BehaviorSubject<Location | null>(null);
  private autocomplete: google.maps.places.Autocomplete | undefined;
  private geocoder: google.maps.Geocoder;

  private placesService: google.maps.places.PlacesService;
  private autocompleteService: google.maps.places.AutocompleteService;

  public isDefaultLocation: boolean = false;

  constructor() {
    this.loadCurrentLocation();
  }

  public loadCurrentLocation(): void {
    const savedLocation = localStorage.getItem("currentLocation");
    if (savedLocation) {
      this.currentLocationSubject.next(JSON.parse(savedLocation));
    } else {
      this.getBrowserLocation();
      if (typeof google !== "undefined") {
        this.geocoder = new google.maps.Geocoder();
      } else {
        this.currentLocationSubject.next(this.setDefaultLocation());
      }
    }
  }

  private getBrowserLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const location: Location = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          this.saveLocation(location);
        },
        (error) => {
          console.error("Geolocation error:", error.message);
          this.currentLocationSubject.next(null);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      this.currentLocationSubject.next(null);
    }
  }

  saveLocation(location: Location): void {
    localStorage.setItem("currentLocation", JSON.stringify(location));
    this.currentLocationSubject.next(location);
    this.isDefaultLocation = false;
  }

  getCurrentLocation(): Observable<Location | null> {
    return this.currentLocationSubject.asObservable();
  }
  // getCurrentLocationValue(): Location | null {
  //   return this.currentLocationSubject.value;
  // }
  getCurrentLocationValue(): Location | null {
    let location = this.currentLocationSubject.value;

    // If the location is not already in memory, check localStorage
    if (!location) {
      const savedLocation = localStorage.getItem("currentLocation");
      if (savedLocation) {
        location = JSON.parse(savedLocation) as Location;
        // Optionally, update the BehaviorSubject with the loaded location
        this.currentLocationSubject.next(location);
      } else {
        location = this.setDefaultLocation();
        this.loadCurrentLocation();
      }
    }

    return location;
  }

  setDefaultLocation() {
    this.isDefaultLocation = true;

    let location = {
      text: "Muscat, Oman",
      primaryText: "Muscat",
      secondaryText: "Oman",
      fullText: "Muscat, Oman",
      latitude: 23.5880307,
      longitude: 58.3828717,
    };
    return location;
  }

  getDefaultMapOptions(): google.maps.MapOptions {
    return {
      center: { lat: -34.397, lng: 150.644 }, // Default center if no location is available
      minZoom: 5,
      zoomControl: true, // Default zoom level,
      zoom: 17,
      mapTypeControl: false, // Optionally hide the "Map" and "Satellite" buttons
      fullscreenControl: false, // Optionally hide the fullscreen button
      streetViewControl: false, // Optionally hide the street view control
      disableDoubleClickZoom: true,
      maxZoom: 20,
    };
  }

  initializeAutocomplete(inputElement: HTMLInputElement): void {
    this.autocomplete = new google.maps.places.Autocomplete(inputElement);
    this.autocomplete.addListener("place_changed", () => {
      const place = this.autocomplete?.getPlace();
      if (place && place.geometry && place.geometry.location) {
        const location: Location = {
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
        };
        this.saveLocation(location);
      }
    });
  }

  getLocationDetails(
    latitude: number,
    longitude: number
  ): Observable<string | null> {
    const latlng = new google.maps.LatLng(latitude, longitude);

    return new Observable((observer) => {
      if (!this.geocoder) {
        this.geocoder = new google.maps.Geocoder();
      }
      this.geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK && results[0]) {
          observer.next(results[0].formatted_address);
          observer.complete();
        } else {
          console.error("Geocoder failed due to:", status);
          observer.next(null);
          observer.complete();
        }
      });
    });
  }

  /**
   * Geocoding: Get coordinates from address.
   * @param address - The address string.
   * @returns Observable with the coordinates (latitude and longitude).
   */
  getCoordinates(address: string): Observable<Location | null> {
    return new Observable((observer) => {
      if (!this.geocoder) {
        this.geocoder = new google.maps.Geocoder();
      }
      this.geocoder.geocode({ address }, (results, status) => {
        if (
          status === google.maps.GeocoderStatus.OK &&
          results[0].geometry.location
        ) {
          const location: Location = {
            latitude: results[0].geometry.location.lat(),
            longitude: results[0].geometry.location.lng(),
          };
          observer.next(location);
          observer.complete();
        } else {
          console.error("Geocoder failed due to:", status);
          observer.next(null);
          observer.complete();
        }
      });
    });
  }

  searchLocation(
    query: string,
    map: google.maps.Map
  ): Observable<Location | null> {
    return new Observable((observer) => {
      this.placesService = new google.maps.places.PlacesService(map);
      this.placesService.textSearch({ query }, (results, status) => {
        if (
          status === google.maps.places.PlacesServiceStatus.OK &&
          results[0].geometry
        ) {
          const location: Location = {
            latitude: results[0].geometry.location.lat(),
            longitude: results[0].geometry.location.lng(),
            text: query,
          };
          observer.next(location);
          observer.complete();
        } else {
          console.error("Places search failed due to:", status);
          observer.next(null);
          observer.complete();
        }
      });
    });
  }

  autocompleteLocation(
    query: string
  ): Observable<google.maps.places.AutocompletePrediction[]> {
    return new Observable((observer) => {
      this.autocompleteService = new google.maps.places.AutocompleteService();
      this.autocompleteService.getPlacePredictions(
        { input: query, componentRestrictions: { country: "OM" } },
        (predictions, status) => {
          if (
            status === google.maps.places.PlacesServiceStatus.OK &&
            predictions
          ) {
            observer.next(predictions);
            observer.complete();
          } else {
            console.error("Autocomplete failed due to:", status);
            observer.next([]);
            observer.complete();
          }
        }
      );
    });
  }

  removeCurrentLocation(): void {
    // Remove the current location from localStorage
    localStorage.removeItem("currentLocation");

    // Reset the currentLocationSubject to null
    this.currentLocationSubject.next(null);
  }

  getLocationParameter() {
    return (
      "latitude=" +
      this.getCurrentLocationValue().latitude +
      "&longitude=" +
      this.getCurrentLocationValue().longitude
    );
  }
  get getCurrentLocationArray(): number[] {
    return [
      this.getCurrentLocationValue().longitude,
      this.getCurrentLocationValue().latitude,
    ];
  }
}
