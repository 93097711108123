<ng-template #template>

    <div class="modal-content">
        <div class="modal-header location-modal-header">
            <h5 class="modal-title f-weight-600">Select Your Location</h5>
            <button type="button" class="btn-close-custom ms-auto" (click)="closeModal()"><i
                    class="fa fa-times"></i></button>
        </div>
        <div class="modal-body p-0">
            <div class="p-2 position-relative">
                <div class="p-2 d-flex justify-content-start location-search-input align-items-center mx-2 my-2">
                    <img src="https://tmdone-cdn.s3.me-south-1.amazonaws.com/tmweb/images/tm/icons/location-search-icn.svg" class="mr-2" alt="pin location">
                    <input type="text" id="map-search" placeholder="Search for area, street name, landmark…"
                        class="w-100 f-16 f-m-14 pe-1 ps-3" autocomplete="off" (keyup)="searchLocation($event)"
                        [(ngModel)]="searchText" (keydown)="handleKeyDown($event)">

                </div>
                @if (isShowResults) {

                <div class="searchList position-absolute z-3 w-100 bg-white p-3">
                    @for (item of locatiionSearchList; track $index) {
                    <div class="p-1 search-item {{$index === highlightedIndex?'highlighted' : ''}}"
                        (click)="selectLocation(item)">

                        <p class="p-0 m-0 text-dark"><img src="https://tmdone-cdn.s3.me-south-1.amazonaws.com/tmweb/images/tm/default-pin.svg" alt=""> &nbsp; <span
                                class="f-weight-600">{{item.primaryText}}</span> &nbsp; <span
                                class="f-weight-300">{{item.secondaryText}}</span></p>

                    </div>

                    }

                </div>

                }

            </div>
            <google-map height="400px" width="100%" [center]="center" [options]="options"
                (mapInitialized)="getInitMap($event)" (mapDragend)="mapDragEnd()" (zoomChanged)="zoomChanged()">
                @if(center){

                <map-marker [draggable]="false" (markerInitialized)="markerInIt($event)"></map-marker>
                }
            </google-map>


        </div>
        <div class="p-3 ">
            <div class="row" (click)="closeModal()">

                @if (currentLocation?.text ) {
                <div>
                    <p class="f-weight-600"><img src="https://tmdone-cdn.s3.me-south-1.amazonaws.com/tmweb/images/tm/default-pin.svg" alt="">
                        {{currentLocation?.text}}</p>
                </div>
                }
                @if(zoom<18){
                    <div>
                        <p class="f-weight-600 mb-2">Please zoom to to continue</p>
                    </div>
                }
                <!-- <div class="col-md-6"> <button type="button" class="btn default-btn-secondary">Go Back</button></div> -->
                <div class="col-md-12"> <button type="button" class="btn  default-btn" (click)="confirmLocation()"
                        [disabled]="zoom<18 || isFetching">
                        @if (isFetching) {
                        Fetching..
                        }@else{
                        Confim
                        }
                    </button></div>
            </div>

        </div>
    </div>

</ng-template>